import * as Swal from "sweetalert2";

window.Main = new (function () {

    var $windowWidth = $(window).width();
    var that = this;

    //Mobile functions
    let destructor = function () {
        $('.navSite').detach().appendTo('.DskNavBox');
        $('body').removeClass('noScroll');
    }

    let constructor = function () {
        $('.navSite').detach().prependTo('.iiNav');
    }

    this.matchesMenu = function () {
        let breackpoint = 1023,
            mql = window.matchMedia("(max-width:" + breackpoint + "px)");

        mediaqueryresponse(mql)

        mql.addListener(mediaqueryresponse)

        function mediaqueryresponse(mql) {
            if (mql.matches) {
                constructor();
            } else {
                destructor();
            }
        }
    }

    //open-close menu
    this.menuToggle = function () {
        $('body').toggleClass('noScroll');
    }

    /**
     * Init
     */
    this.init = function () {

        $('.goTo').on('click', that.scrollToElement);
        //mobile menu
        that.matchesMenu();

        $('.hamburger').on('click', that.menuToggle);

        that.headerClass();

        try {
            $('.slick-maintenance').slick({
                dots: false,
                prevArrow: $('.prev.maintenance'),
                nextArrow: $('.next.maintenance'),
                slidesToShow: 1,
                infinite: false
            });
        } catch (e) {
            console.log(e);
        }

        $('.img-circle img').on('click', function (){
            let theElement = $(this);
            let randoAnimation = animations[Math.floor(Math.random()*animations.length)];
            $.each(animations, function (index, value){
                $(theElement).removeClass(value);
            });
            $(this).toggleClass('animate');

            if($(this).hasClass('animate')) {
                $(this).addClass(randoAnimation);
            }
        });
        $('.img-circle img').hover(function (){
            let theElement = $(this);
            let randoAnimation = animations[Math.floor(Math.random()*animations.length)];
            $.each(animations, function (index, value){
                $(theElement).removeClass(value);
            });
            $(this).toggleClass('animate');

            if($(this).hasClass('animate')) {
                $(this).addClass(randoAnimation);
            }
        });

        $('.ii-inflate-clients .list .item img').hover(function (){
            $(this).toggleClass('animate');
        });

        $('.list .item').on('click', function (){
            let goTo = $(this).data('slide');
            $('.slick-maintenance').slick('slickGoTo', goTo);
        });

        $(".slick-maintenance").on('beforeChange', function(event, slick, currentSlide, nextSlide){
            let slideInfo = $('.slide-' + nextSlide);
            let title = $(slideInfo).data('title');
            let text = $(slideInfo).data('text');

            $('.list .item').removeClass('active');
            $('.list .item-' + nextSlide).addClass('active');

            $('#maintenance-title').html(title);
            $('#maintenance-description').html(text);
        });

        $('.slick-contact').slick({
            dots: false,
            arrows: false,
            slidesToShow: 1,
            infinite: true,
            autoplay: true,
            fade: true
        })
    };


    this.carrousels = function () {

        $('.open-gallery').on('click', that.openGallery);

    } ;

    this.openGallery = function (e) {
        e.preventDefault();

        let galleryToOpen = $(this).data('gallery');
        let galleryToOpenClass = $(this).data('class');
        var inflateGallery = '';

        if(galleryToOpenClass == 'publicity') {
            inflateGallery = inflatePublicityGallery;
        }
        if(galleryToOpenClass == 'structures') {
            inflateGallery = inflateStructuresGallery;
        }
        if(galleryToOpenClass == 'botargas') {
            inflateGallery = inflateBotargasGallery;
        }
        if(galleryToOpenClass == 'interactive') {
            inflateGallery = inflateInteractiveGallery;
        }
        if(galleryToOpenClass == 'more-products') {
            inflateGallery = inflateMoreGallery;
        }

        Swal.fire({
            html: inflateGallery,
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false
        });

        $('.arrows-slider').removeClass('d-none');

        $('.' + galleryToOpen).slick({
            dots: false,
            prevArrow: $('.prev.inflate'),
            nextArrow: $('.next.inflate'),
            slidesToShow: 1,
            infinite: false
        });

    };

    this.scrollToElement = function () {
        let goToElement = $(this).data('place');

        $('.ii-navLeft li a').removeClass('active');
        $(this).addClass('active');

        $('.hamburger').trigger('click');

        $('html, body').animate({
            scrollTop: $("#" + goToElement).offset().top
        }, 1000);
    };

    this.unSlick = function () {
        try {
            $('.slick-maintenance').slick('unslick');
        } catch (e) {
            console.log(e);
        }

    };

    //add class to header on scroll
    this.headerClass = function () {
        $(window).scroll(function () {
            if ($(window).scrollTop() > 15) {
                $('header').addClass('stick')
            } else {
                $('header').removeClass('stick')
            }
            if ($('body').hasClass('is_team')) {
                $('header').addClass('stick');
            }
        })
    }

})();

$(window).resize(function () {
    var $windowWidth = $(window).width();
    if ($windowWidth > 991) {
        //Main.unSlick();
    } else {
        //Main.init();
    }
});
